import { FC, useEffect, useState, useRef } from 'react';
import uuid from 'react-uuid';
import {
    Link, useNavigate 
} from "react-router-dom";

import { Formik, Field, Form, FormikProps } from 'formik'
import * as yup from 'yup';
import { string } from 'yup/lib/locale';
import MemberService from './service';
import Member from '../../Models/Member'; 
import { useTranslation } from 'react-i18next'; 
import * as GLOBAL from "../../Utils";

interface MemberFormProps {
    state: string;
    member?: Member | null; 
}

const MemberForm: FC<MemberFormProps> = ( {  state, member } ) => {
   

    let navigate = useNavigate();
    const { t, i18n } = useTranslation( );

    const formRef = useRef< FormikProps< Member >>(null);

    let [image, setImage] = useState('');

    let [loading, setLoading] = useState(false);

    let [memberFileLabel, setAgentFileLabel] = useState('');
    let [memberFile, setAgentFile] = useState('');

    let [ memberFiles, setAgentFiles ] = useState(Array<any>);

    let [memberTypesLoading, setAgentTypesLoading] = useState(false);
    let [ transactionMethodsLoading, setTransactionMethodsLoading ] = useState(false);

    let [ countriesLoading, setCountriesLoading ] = useState(false);

    let [ shopsLoading, setShopsLoading ] = useState(false);

    let [ shops, setShops ] = useState([]);

    let [ pieceTypesLoading, setPieceTypesLoading ] = useState(false);
    let [ pieceTypes, setPieceTypes ] = useState([]);

    let [ countries, setCountries ] = useState([]);

    let galeryIdInit: any = null;
    let [galId, setGalId] = useState(galeryIdInit);

    let [
        memberTypes, setAgentTypes
    ] = useState([]);

    let [
        transactionMethods, setTransactionMethods
    ] = useState([]);

 
    const country_phone_code_init: string = '';
 
    const [
        countryPhoneCode, setCountryPhoneCode
    ] = useState(country_phone_code_init);


    const memberService = new MemberService(); 
    
    const readThis = (inputValue: any, filetype: string): void => {
        var file: File = inputValue.files[0];
        var lecteur: FileReader = new FileReader();
    
        lecteur.onloadend = (e) => {
            switch (filetype) {
                case 'profile':
                    setImage( lecteur.result as string );
                    break;
            
                default:
                    setImage( lecteur.result as string );
                    break;
            }
            
            
            
            console.log(lecteur.result);
        }
        lecteur.readAsDataURL(file);

    }

    const changeListener = (event: any, filetype: string = 'profile')  => {
        readThis(event.target, filetype);
    }

    const getAgentTypeList = () => {
        setAgentTypesLoading(true);
        memberService.getAgentTypeList().then(async function (response: any) {
            console.log(response);
            setAgentTypes(response.data?.data);
            setAgentTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setAgentTypesLoading(false); 
        });
    }

    const getTransactionsMethods = () => {
        setTransactionMethodsLoading(true);
        memberService.getTransactionsMethods().then(async function (response: any) {
            console.log(response);
            setTransactionMethods(response.data?.data);
            setTransactionMethodsLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setTransactionMethodsLoading(false); 
        });
    }

    const getCountries = () => {
        setCountriesLoading(true);
        memberService.getCountries().then(async function (response: any) {
            console.log(response);
            setCountries(response.data.countries);
            setCountriesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setCountriesLoading(false); 
        });
    }
 
    const sendAgentFile = (event: any) => {
        console.log("Add file");
        if (memberFile && memberFileLabel) {

            console.log(memberFiles);
            let files = Array.isArray(memberFiles) ? memberFiles : [];
            files.push({
                'label':  memberFileLabel,
                'file':  memberFile
            });
            setAgentFiles(files);

            setAgentFile('');
            setAgentFileLabel('');

        }  else {
            alert( `${t("Vous devez remplir le champ label et choisir un fichier")}` );
        }
        // if (formRef.current !== null) {
        //     if (formRef.current.values.adminFileLabel !== null 
        //         && formRef.current.values.adminFileLabel !== '' && formRef.current.values.adminFile !== null 
        //         && formRef.current.values.adminFile !== '') {
       
        //         formRef.current.values.adminFileLabel = '';
        //         formRef.current.values.adminFile = '';
        //     } else {
        //         alert("Remplir le champ label et choisir un fichier");
        //     }
        // }   
    }

    const getPieceTypesList = () => {
        setPieceTypesLoading(true);
        memberService.getPieceTypes().then(async function (response: any) {
            console.log(response);
            setPieceTypes(response.data?.data);
            setPieceTypesLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setPieceTypesLoading(false); 
        });
    }
 

    useEffect( () => {

        getCountries();

        if (member != null  && member != undefined) {
            console.log(member);
            setImage( GLOBAL.api_media_url + "/membres/" + member?.photo ?? '');
        }

    }, []);

    

 


    const isBase64 = (file: string) => {
        return file.includes('data');
        // if (file ==='' || file.trim() ===''){ return false; }
        // try {
        //     return window.btoa(window.atob(file)) == file;
        // } catch (err) {
        //     return false;
        // }
    } 

    const getMemberStatus = (status: string | null | undefined) => {
        if (status !== null) {
            switch(status) {
                case "new":
                    return "Nouveau membre";
                    break;
                case "new":
                    return "Ancien membre";
                    break;
                default:
                    return "Non défini";
                    break; 
            }
        }
        return "";
    }
    
    

    return (
        <Formik
        initialValues={
            member != null  && member != undefined ? 
            {
                ...member
            }:
            {
                status: null,
                statu: null,
                firstname: '',
                lastname: '',
                email: '',
                phone_number: '',
                birth_date:  '',
                country: '',
                residence_country: '',
                nationality: '',
                adress: '',
                profession: '',
                photo: '',
                created_at: '',
                updated_at: '',
                centerOfInterest: null,
                sponsor: ''        }}
        validationSchema={
            yup.object().shape({
                status: yup
                    .string() 
                    .required('Ce champ est obligatoire'),
                firstname: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                lastname: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                email: yup
                    .string() 
                    .email('Email incorrect')
                    .required('Ce champ est obligatoire')
                    .test('checkEmailUnique', "Cet email a été déjà utilisé", value =>
                        memberService.verifyEmail(value ?? '')
                        .then((res) => { 
                            console.log(res);
                            return  res.data.email ? res.data.email === member?.email ? true : false : true;
                        })
                        .catch((e) => {
                            console.log(e);
                            return false;
                        })
                    ),
                phone_number: yup
                    .string() 
                    .required('Ce champ est obligatoire') 
                    .test('checkPhoneNumberUnique', "Ce numéro de téléphone a été déjà utilisé", value =>
                    memberService.verifyPhoneNumber(value ?? '')
                        .then((res) => {
                            return res.data.phone_number ? res.data.phone_number === member?.phone_number ? true : false : true;
                        })
                        .catch((e) => {
                            console.log(e);
                            return false;
                        })
                    ),
                birth_date:  yup
                    .date() 
                    .required(`${t('Ce champ est obligatoire')}`),
                country: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                residence_country: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                nationality: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                adress: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                profession: yup
                    .string() 
                    .required(`${t('Ce champ est obligatoire')}`),
                photo: yup
                    .string(),
                sponsor: yup
                    .string(),
                centerOfInterest: yup
                    .mixed<string>()
                    .required('Ce champ est obligatoire')
            })
        }
        onSubmit={async (
            values 
          ) => {
                    console.log(values);
                    setLoading(true);

                    if (image != null && image != '' && image != GLOBAL.api_media_url + "/membres/" + member?.photo ) {
                        values.photo = image;
                    }

                    if (state == 'create') {
                        
                    } else if (state == 'edit') {
                        memberService.putMember( { ...values, statu: values.status } ).then(async function (response: any) {
                            console.log(response);
                            alert( `${t("Membre mise à jour avec succès")}`);  
                            navigate('/member/list');
                            setLoading(false); 
                        })
                          .catch(function (error: any) {
                            console.log(error);
                            alert(`${ t('Une erreur s\'est produite réesseyez s\'il vous plaît') }`)
                            setLoading(false); 
                        });
                    }
                    
                }}
                innerRef={formRef}
                >
                {({ dirty, errors, touched, isValid, handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                                    <div className="form-body row"> 
                                        <div className='col-md-4'>
                                            <div style={{ margin: "0 auto",
                                             borderRadius: "50%",    }} >
                                               <img style={{ width:"200px", height:"200px", margin: "0 auto", borderRadius: "50%" }} 
                                               src={ image != null && image != "" ? image : "assets/images/user.png" }alt="" />

                                               {/* <div style={{ width:"50px", height:"50px", position: "absolute", right: "45%", 
                                               bottom: "55%", borderRadius: "50%",  backgroundColor: "#edd663", cursor: "pointer"  }}>
                                                    <label className='fas fa-image fa-2x' style={{ color: "white", marginTop: "8px", 
                                                    marginLeft: "8px", cursor: "pointer"  }} htmlFor="photoProfil" ></label>
                                                    <input type="file" 
                                                            className={`form-control custom-file-input `} 
                                                                id="photoProfil" 
                                                                onChange={changeListener} 
                                                                placeholder="Photo" />
                                               </div> */}
                                                <div style={{ width:"50px", height:"50px", position: "absolute", right: "45%", 
                                                                                bottom: "70%", borderRadius: "50%",  backgroundColor: "#edd663", cursor: "pointer"  }}>
                                                                                        <label className='fas fa-image fa-2x' style={{ color: "white", marginTop: "8px", 
                                                                                        marginLeft: "8px", cursor: "pointer"  }}
                                                                                         htmlFor="photoProfil" ></label>
                                                                                        <input type="file" 
                                                                                                className={`form-control custom-file-input `} 
                                                                                                    id="photoProfil" 
                                                                                                    onChange={changeListener} 
                                                                                                    placeholder="Photo" />
                                                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-8'> 
                                            <div className='row'> 

                                            <div className="form-group col-md-12">
                                    <label htmlFor="">
                                        Statut du membre: <b>{ getMemberStatus(values.status) }</b> </label> <br/> 
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="">Prénom</label>
                                    <input  className={`form-control ${ errors.firstname && touched.firstname ? "is-invalid":""}`}
                                                                        id="firstname" 
                                                                        name="firstname"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('firstname')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('firstname')}
                                                                        value={values.firstname == null ? ''  :
                                                                         values.firstname }
                                                                        placeholder="Prénom" />
                                                                    { errors.firstname && touched.firstname &&
                                                                     errors.firstname && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.firstname && touched.firstname &&
                                                                         errors.firstname }
                                                                </div> }
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="">Nom</label>
                                <input  className={`form-control ${ errors.lastname && touched.lastname ? "is-invalid":""}`}
                                                                        id="lastname" 
                                                                        name="lastname"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('lastname')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('lastname')}
                                                                        value={values.lastname == null ? ''  :
                                                                         values.lastname }
                                                                        placeholder="Nom" />
                                                                    { errors.lastname && touched.lastname &&
                                                                     errors.lastname && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.lastname && touched.lastname &&
                                                                         errors.lastname }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Pays d'origine</label>
                                {   countriesLoading ? <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : <select className={`form-control custom-select ${ errors.country &&
                                                                    touched.country ? "is-invalid" : "" }`}
                                                                id="country" 
                                                                name="country"
                                                                onChange={event => { 
                                                                    handleChange('country')(event); 
                                                                }}
                                                                onBlur={handleBlur('country')}
                                                                value={values.country == null ? '' : values.country}
                                                                placeholder="Pays d'origine" >
                                    <option value="">Pays d'origine</option>

                                    {
                                        countries.map((country: any, index: number) => <option key={index} value={ country.nicename }>{ country?.nicename }</option>)
                                    }
                                    {/* <option value="Togo">Togo</option>
                                    <option value="Bénin">Bénin</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Burkina-Faso">Burkina-Faso</option> */}
                                </select> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Pays de résidence</label>
                                {  countriesLoading  ? <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div> : <select className={`form-control custom-select ${ errors.residence_country &&
                                                                    touched.residence_country ? "is-invalid" : "" }`}
                                                                id="residence_country" 
                                                                name="residence_country"
                                                                onChange={event => { 
                                                                    handleChange('residence_country')(event); 
                                                                    
                                                                    if (event.target.value !== '') {
                                                                        const ctry: Array<any> =  countries.filter((crt: any) =>  crt.nicename === event.target.value);
                                                                        if (ctry.length > 0) setCountryPhoneCode(ctry[0].phonecode);
                                                                        else setCountryPhoneCode('');
                                                                    }
                                                                }}
                                                                onBlur={handleBlur('residence_country')}
                                                                value={values.residence_country == null ? '' : values.residence_country}
                                                                placeholder="Pays de résidence" >
                                    <option value="">Pays de résidence</option>
                                    {
                                        countries.map((country: any, index: number) => <option key={index} value={ country.nicename }>{ country?.nicename }</option>)
                                    }
                                    {/* <option value="Togo">Togo</option>
                                    <option value="Bénin">Bénin</option>
                                    <option value="Ghana">Ghana</option>
                                    <option value="Burkina-Faso">Burkina-Faso</option> */}
                                </select> }
                            </div>


                            


                            <div className="form-group col-md-6">
                                <label htmlFor="">Nationalité</label>
                                <input  className={`form-control ${ errors.nationality && touched.nationality ? "is-invalid":""}`}
                                                                        id="nationality" 
                                                                        name="nationality"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('nationality')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('nationality')}
                                                                        value={values.nationality == null ? ''  :
                                                                         values.nationality }
                                                                        placeholder="Nationalité" />
                                                                    { errors.nationality && touched.nationality &&
                                                                     errors.nationality && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.nationality && touched.nationality &&
                                                                         errors.nationality }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Date de naissance</label>
                                <input type="date" className={`form-control ${ errors.birth_date && touched.birth_date ? "is-invalid":""}`}
                                                                        id="birth_date" 
                                                                        name="birth_date"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('birth_date')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('birth_date')}
                                                                        value={values.birth_date == null ? ''  :
                                                                         values.birth_date }
                                                                        placeholder="Date de naissance" />
                                                                    { errors.birth_date && touched.birth_date &&
                                                                     errors.birth_date && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.birth_date && touched.birth_date &&
                                                                         errors.birth_date }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="">Numéro de téléphone</label>
                                <div className="input-group mb-2" >
                                    {/* <div className="input-group-prepend">
                                        <div className="input-group-text" >{ countryPhoneCode }</div>
                                    </div> */}

                                    <input style={{ marginLeft: 10 }} type='tel'  className={`form-control ${ errors.phone_number && touched.phone_number ? "is-invalid":""}`}
                                                                        id="phone_number" 
                                                                        name="phone_number"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('phone_number')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('phone_number')}
                                                                        value={values.phone_number == null ? ''  :
                                                                         values.phone_number }
                                                                        placeholder="Numéro de téléphone" />
                                                                    { errors.phone_number && touched.phone_number &&
                                                                     errors.phone_number && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.phone_number && touched.phone_number &&
                                                                         errors.phone_number }
                                                                </div> }
                                </div>
                            </div>


                            <div className="form-group col-md-6">
                                <label htmlFor="">Email</label>
                                <input  disabled={true}  className={`form-control ${ errors.email && touched.email ? "is-invalid":""}`}
                                                                        id="email" 
                                                                        name="email"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('email')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('email')}
                                                                        value={values.email == null ? ''  :
                                                                         values.email }
                                                                        placeholder="Email" />
                                                                    { errors.email && touched.email &&
                                                                     errors.email && 
                                                                <div id="validationServer05Feedback" 
                                                                className="invalid-feedback">
                                                                        { errors.email && touched.email &&
                                                                         errors.email }
                                                                </div> }
                            </div>
 
                            <div className="form-group col-md-6">
                                <label htmlFor="">Adresse</label>
                                <input  className={`form-control ${ errors.adress && touched.adress ? "is-invalid":""}`}
                                                                        id="adress" 
                                                                        name="adress"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('adress')(event); 
                                                                        }}
                                                                        onBlur={handleBlur('adress')}
                                                                        value={values.adress == null ? ''  :
                                                                         values.adress }
                                                                        placeholder="Adresse" />
                                                                    { errors.adress && touched.adress &&
                                                                     errors.adress && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.adress && touched.adress &&
                                                                         errors.adress }
                                                                </div> }
                            </div>
 

                            <div className="form-group col-md-6">
                                <label htmlFor="">Profession</label>
                                <input className={`form-control ${ errors.profession && touched.profession ? "is-invalid":""}`}
                                                                        id="profession" 
                                                                        name="profession"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('profession')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('profession')}
                                                                        value={values.profession == null ? ''  :
                                                                         values.profession }
                                                                        placeholder="Profession" />
                                                                    { errors.profession && touched.profession &&
                                                                     errors.profession && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.profession && touched.profession &&
                                                                         errors.profession }
                                                                </div> }
                            </div>


                            <div className="form-group col-md-6">
                                <label htmlFor="">Sponsor</label>
                                <input disabled className={`form-control ${ errors.sponsor && touched.sponsor
                                 ? "is-invalid":""}`}
                                                                        id="sponsor" 
                                                                        name="sponsor"
                                                                        onChange={(event: any) => { 
                                                                            handleChange('sponsor')(event);
                                                                            // getProductByBarcode();
                                                                        }}
                                                                        onBlur={handleBlur('sponsor')}
                                                                        value={values.sponsor == null ? ''  :
                                                                         values.sponsor }
                                                                        placeholder="Sponsor" />
                                                                    { errors.sponsor && touched.sponsor &&
                                                                     errors.sponsor && 
                                                                <div id="validationServer05Feedback" 
                                                                    className="invalid-feedback">
                                                                        { errors.sponsor && touched.sponsor &&
                                                                         errors.sponsor }
                                                                </div> }
                            </div>

                            <div className="form-group col-md-12">
                                                                    <label htmlFor="">Quel sont vos centres d'intérêt ? </label> <br/>

                                                                    <div style={{ marginLeft: "10px" }} role="group" aria-labelledby="checkbox-group">
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Développement Personnel" />
                                                                            Développement Personnel
                                                                        </label>
                                                                        <br/>
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Formation sur l'Épargne et la Tontine" />
                                                                            Formation sur l'Épargne et la Tontine
                                                                        </label>
                                                                        <br/>
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Initiation aux marchés des valeurs obligataires et participatives (Actions/Obligations)" />
                                                                            Initiation aux marchés des valeurs obligataires et participatives (Actions/Obligations)
                                                                        </label>
                                                                        <br/>
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Coaching Immobilier" />
                                                                            Coaching Immobilier
                                                                        </label>
                                                                        <br/>
                                                                        <label>
                                                                            <Field type="checkbox" name="centerOfInterest" value="Accompagnement financier de StartUps" />
                                                                            Accompagnement financier de StartUps
                                                                        </label>
                                                                    </div>

                                                                </div>







                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-actions">
                                        <div className="text-right">
                                            <button  disabled={!isValid} type="submit" className="btn btn-info">
                                                { t('Modifier') }
                                                {
                                                    loading && <div className="spinner-border text-light spinner-border-sm" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                            <button type="reset" className="btn btn-dark">
                                                { t('Annuler') }
                                            </button>
                                        </div>
                                    </div>
                        </Form>
                    )}
                </Formik>
    );
}

export default MemberForm