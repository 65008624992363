// PRODUCTION
// let _serverUrl = "api.kekeliedu.net/AAIC";

// DEVELOPMENT
// let _serverUrl = "dev.api.aaic-global.org";

let _serverUrl = "dev.api.aaic-global.org";

if (process.env.NODE_ENV !== "development") {
    _serverUrl = "api.kekeliedu.net/AAIC";
}

// "lapotnewapi.nogdevhouse.com";
// "localbackend.nogdevhouse.com";

// let apiProtocol = "http:";

// if (apiProtocol === undefined) {
//   apiProtocol = window.location.protocol;
// } 

let baseApiPath = "https://" + _serverUrl + "/ADMIN";

export const api_url = baseApiPath;
export const api_media_url = "https://" + _serverUrl + "/medias";
